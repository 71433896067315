<template>
  <dl class="grid grid-cols-2 divide-y divide-gray-200">
    <div class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Tracking ID</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex-grow">
          <pre>{{ version.trackingId }}</pre>
        </span>
      </dd>
    </div>
    <div class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Contact Name</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <template v-if="version.contactName">{{ version.contactName }}</template>
        <template v-else><span class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">Not Set</span></template>
      </dd>
    </div>
    <div class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Phone Number</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex-grow">
          <pre v-if="version.phoneNumber">{{ version.phoneNumber }}</pre>
          <template v-else><span class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">Not Set</span></template>
        </span>
      </dd>
    </div>
    <div class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Clerk ID</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex-grow">
          <pre v-if="version.clerkId">{{ version.clerkId }}</pre>
          <template v-else><span class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">Not Set</span></template>
        </span>
      </dd>
    </div>
    <div class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Counter ID</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex-grow">
          <pre v-if="version.counterId">{{ version.counterId }}</pre>
          <template v-else><span class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">Not Set</span></template>
        </span>
      </dd>
    </div>
    <div class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Drop off location</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" v-if="hasLocation()">
        <span class="flex-grow">
          <pre>{{ version.location.latitude }}, {{ version.location.longitude }}</pre>
        </span>
        <span class="flex-shrink-0 ml-4">
          <div class="flex flex-col-reverse mt-6 space-y-4 space-y-reverse justify-stretch sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
            <a target="_blank" :href="getGoogleMapsLink()" class="inline-flex items-center px-4 py-1 text-sm text-white rounded-md bg-pol-red hover:bg-pol-red-dark">
              <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg> View Map </a>
          </div>
        </span>
      </dd>
      <dd v-else class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex-grow">
          <span class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">Not Set</span>
        </span>
      </dd>
    </div>
    <div class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Expiry</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span v-if="isNotEmptyDate(version.expiryDate)" class="flex-grow">{{ version.expiryDate | moment(dateDisplayFormat)}}</span>
        <template v-else>
          <span class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">Not Set</span>
        </template>
      </dd>
    </div>
    <div class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Delivered Date Time</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span v-if="isNotEmptyDate(version.deliveredDateTime)" class="flex-grow">{{ version.deliveredDateTime | moment(dateDisplayFormat)}}</span>
        <template v-else>
          <span class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">Not Set</span>
        </template>
      </dd>
    </div>
    <div class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Label ID</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex-grow">
          <pre v-if="version.labelId">{{ version.labelId }}</pre>
          <template v-else><span class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">Not Set</span></template>
        </span>
      </dd>
    </div>
    <div class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Receipt ID</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex-grow">
          <pre v-if="version.receiptId">{{ version.receiptId }}</pre>
          <template v-else><span class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">Not Set</span></template>
        </span>
      </dd>
    </div>
    <div class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Observation Code</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex-grow">
          <pre v-if="version.observationCode">{{ version.observationCode }}</pre>
          <template v-else><span class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">Not Set</span></template>
        </span>
      </dd>
    </div>
    <div class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Captured ID</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex-grow" v-if="version.capturedId && version.capturedId.length">
          <pre>{{ version.capturedId.join(', ')}}</pre>
        </span>
        <template v-else><span class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">Not Set</span></template>
      </dd>
    </div>
    <div class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Prevent pickup</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex-grow">
          <span v-if="version.preventCustomerPickup" class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">Yes</span>
          <span v-else class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">No</span>
        </span>
      </dd>
    </div>
    <div class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Unlabelled</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex-grow">
          <span v-if="version.unlabelled" class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">Yes</span>
          <span v-else class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">No</span>
        </span>
      </dd>
    </div>
    <div class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Refused</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex-grow">
          <span v-if="version.refused" class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">Yes</span>
          <span v-else class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">No</span>
        </span>
      </dd>
    </div>
    <div class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Last customer collection attempt</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex-grow">
          <template v-if="version.lookupReason">
            <pre>{{ getFormattedLookupReason(version.lookupReason) }}</pre>
            <span class="text-xs">{{ version.lookupReasonDateTime | moment(dateDisplayFormat) }}</span>
          </template>
          <template v-else><span class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">Not Set</span></template>
        </span>
      </dd>
    </div>
    <div class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Assumed as collected (<i>Auto system set</i>)</dt>
      <dd class="flex flex-col gap-2 mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex items-start gap-2">
          <span v-if="version.assumedCollection" class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full">Yes</span>
          <span v-else class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
            No
          </span>
        </span>
        <template v-if="!version.assumedCollection">
          <div class="flex items-center" v-if="version.sk == 'V0'">
            <div class="mr-2">
              <label for="allow-assumed-collection-override" class="text-sm font-medium text-gray-900">Block auto system collection decision</label>
            </div>
            <input @click="triggerAssumedCollectionFlow = !triggerAssumedCollectionFlow" id="allow-assumed-collection-override" name="allow-assumed-collection-override" type="checkbox" :checked="version.preventAssumedCollectionProcessing" class="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500">
          </div>
        </template>
      </dd>
    </div>
    <div class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Status</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex-grow">
          <span class="inline-flex px-2 text-xs font-semibold leading-5 text-black bg-gray-100 rounded-full">
            {{ version.status }}
          </span>
          <span v-if="version.canUpdateStatus" @click="editStatus()" class="edit">Edit</span>
        </span>
      </dd>
      <template v-if="showUpdateStatus">
        <dt class="text-sm font-medium text-pol-grey">Status*</dt>
        <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span class="flex-grow">
            <span class="inline-flex px-2 text-xs font-semibold leading-5 text-black">
              <label for="changeStatus" class="sr-only">Status</label>
              <div>
                <option value="">Select Status</option>
                  <select v-model="parcelStatus" class="px-4 py-3 pr-4 mr-4 placeholder-black border border-gray-300 rounded-md lg:max-w-full bg-gray-50" required>
                    <template v-if="version.carrierId == 'dhl'">
                      <option v-for="status in parcelStatusesForDHL" :key="status" :value="status">
                        {{status}}
                      </option>
                    </template>
                    <template v-else-if="version.carrierId == 'evri'">
                      <option v-for="status in parcelStatusesForEvri" :key="status" :value="status">
                        {{status}}
                      </option>
                    </template>
                    <template v-else>
                      <option v-for="status in parcelStatuses" :key="status" :value="status">
                        {{status}}
                      </option>
                    </template>
                  </select>
                <div v-if="availableActionsError" class="text-pol-red">
                  {{availableActionsErrorMessage}}
                </div>
              </div>
            </span>
          </span>
        </dd>
        <dt class="text-sm font-medium text-pol-grey">Reason*</dt>
        <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span class="flex-grow">
            <span class="inline-flex px-2 text-xs font-semibold leading-5 text-black">
              <label for="reason" class="sr-only">Reason</label>
                <select v-model="reasonChange" class="px-4 py-3 pr-4 mr-4 placeholder-black border border-gray-300 rounded-md lg:max-w-full bg-gray-50" required>
                  <option value="">Select Reason</option>
                  <option v-for="item in reasonCodes" :key="item" :value="item">{{ item }}</option>
                </select>
            </span>
          </span>
        </dd>
        <dt class="text-sm font-medium text-pol-grey">Additional Information</dt>
        <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span class="flex-grow">
            <span class="inline-flex px-2 text-xs font-semibold leading-5 text-black">
              <label for="additionalInfo" class="sr-only">Additional Information</label>
              <textarea rows="2" name="additionalInfo" class="px-4 py-3 pr-4 mr-4 placeholder-black border border-gray-300 rounded-md lg:max-w-full bg-gray-50" placeholder="Please provide additional information for the status change.." v-model="additionalInfo" />
            </span>
            <div class="mt-6">
              <button @click="updateStatusClick()" type="submit" class="flex items-center justify-center w-full px-5 py-2 mt-3 text-base font-medium text-white border border-transparent rounded-md bg-pol-red hover:bg-pol-red-dark sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0">
                    Update
              </button>
            </div>
          </span>
        </dd>
      </template>
    </div>
    <div v-if="version.reason" class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Reason for Status Change</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex-grow">
          <span>{{ version.reason }}</span>
        </span>
      </dd>
    </div>
    <div v-if="version.statusChangedBy" class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Status Changed By</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex-grow">
          <span>{{ version.statusChangedBy }}</span>
        </span>
      </dd>
    </div>
    <div v-if="version.additionalInfo" class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Additional Information for the status change</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex-grow">
          <span>{{ version.additionalInfo }}</span>
        </span>
      </dd>
    </div>
    <div v-if="version.shipmentId" class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Shipment ID</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex-grow">
          <pre>{{ version.shipmentId }}</pre>
        </span>
      </dd>
    </div>
    <div v-if="version.dispatchCode" class="parcel-row">
      <dt class="text-sm font-medium text-pol-grey">Dispatch Code</dt>
      <dd class="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span class="flex-grow">
          <pre>{{ version.dispatchCode }}</pre>
        </span>
      </dd>
    </div>
    <div v-if="version.carrierId == 'dhl' && version.status == 'BRANCH_CHECKED_IN' && version.canUpdateStatus && version.pin" class="parcel-row">
      <button @click="regeneratePinClick(version)" type="submit" class="flex items-center justify-center w-full px-5 py-2 mt-3 text-base font-medium text-white border border-transparent rounded-md bg-pol-red hover:bg-pol-red-dark sm:mt-0 sm:ml-15 sm:w-auto sm:flex-shrink-0">
        Regenerate pin</button>
    </div>
  </dl>
</template>

<script>

import { Auth } from 'aws-amplify'
export default {
  name: 'Default',
  props: {
    version: {
      required: true,
      type: Object
    }
  },
  data () {
    const reasonCodesList = JSON.parse(process.env.VUE_APP_REASON_CODES)
    return {
      dateDisplayFormat: 'dddd, MMMM Do YYYY, h:mm:ss a',
      triggerAssumedCollectionFlow: false,
      showUpdateStatus: false,
      parcelStatus: '',
      reasonChange: '',
      additionalInfo: '',
      reasonCodes: reasonCodesList,
      lookupReasons: {
        CUSTOMER_PICK_UP_WITH_PASS: 'Agent scanned pickup pass',
        CUSTOMER_PICK_UP_BY_NAME: 'Agent searched manually with name',
        CUSTOMER_PICK_UP_BY_MANUAL_TRACKING_ID: 'Agent searched manually with tracking id'
      },
      parcelStatuses: [
        'CARRIER_DROPPED_OFF',
        'CARRIER_COLLECTED',
        'BRANCH_CHECKED_IN',
        'CUSTOMER_COLLECTED',
        'BRANCH_UNRECOGNISED',
        'CUSTOMER_REFUSED',
        'EXPIRED_COLLECTION',
        'CUSTOMER_DROPPED_OFF',
        'CUSTOMER_ID_CHECK_FAILED',
        'READY_FOR_CARRIER_COLLECTION',
        'CUSTOMER_RECLAIMED',
        'PARCEL_NOT_IN_BRANCH'
      ],
      parcelStatusesForDHL: [
        'CARRIER_COLLECTED',
        'BRANCH_CHECKED_IN',
        'CUSTOMER_COLLECTED',
        'BRANCH_UNRECOGNISED',
        'CUSTOMER_REFUSED',
        'EXPIRED_COLLECTION',
        'CUSTOMER_DROPPED_OFF',
        'CUSTOMER_ID_CHECK_FAILED',
        'READY_FOR_CARRIER_COLLECTION',
        'CUSTOMER_RECLAIMED',
        'PARCEL_NOT_IN_BRANCH'
      ],
      parcelStatusesForEvri: [
        'EXPECTED_IN_BRANCH',
        'CARRIER_COLLECTED',
        'BRANCH_CHECKED_IN',
        'CUSTOMER_COLLECTED',
        'BRANCH_UNRECOGNISED',
        'CUSTOMER_REFUSED',
        'EXPIRED_COLLECTION',
        'CUSTOMER_DROPPED_OFF',
        'CUSTOMER_ID_CHECK_FAILED',
        'READY_FOR_CARRIER_COLLECTION',
        'CUSTOMER_RECLAIMED',
        'PARCEL_NOT_IN_BRANCH',
        'STOP_AND_RETURN',
        'DRIVER_DROPPED_OFF',
        'DRIVER_COLLECTED'
      ],
      availableActionsError: false,
      availableActionsErrorMessage: process.env.VUE_APP_AVAILABLE_ACTIONS_ERROR
    }
  },
  created () {
    if (this.version.carrierId === 'dpdni') {
      this.getAvailableActions()
    }
  },
  watch: {
    triggerAssumedCollectionFlow: async function () {
      const newState = !this.version.preventAssumedCollectionProcessing

      if (confirm(`Are you sure you want to set the automated remediation for assumed customer collection to ${newState}?\n\nSetting this value to true will prevent the system from identifying this parcel as potentially customer collected based on a lookup event received by horizon - if false, this will be ignored from the processing`)) {
        try {
          const user = await Auth.currentAuthenticatedUser()
          this.$http.defaults.headers.common.Authorization = user.signInUserSession.idToken.jwtToken
          const apiPath = `${process.env.VUE_APP_API_BASE}/actions/preventAssumedCollectionProcessing`
          await this.$http.post(apiPath, {
            carrierId: this.version.carrierId,
            trackingId: this.version.trackingId,
            value: newState
          })
          this.version.preventAssumedCollectionProcessing = newState
        } catch (error) {
          alert('An error occurred setting this flag, please try again')
        }
      }
    }
  },
  methods: {
    /**
     * Has Location
     */
    hasLocation () {
      if (!this.version.location) {
        return false
      }

      if (this.version.location.latitude && this.version.location.longitude) {
        return true
      }

      return false
    },
    editStatus () {
      this.showUpdateStatus = true
    },
    async updateStatusClick () {
      if (!this.parcelStatus) {
        alert('Please Select Parcel Status')
        return
      }
      if (!this.reasonChange) {
        alert('Please Select Reason')
        return
      }

      try {
        const user = await Auth.currentAuthenticatedUser()
        this.$http.defaults.headers.common.Authorization = user.signInUserSession.idToken.jwtToken
        const apiPath = `${process.env.VUE_APP_API_BASE}/actions/updateParcelStatusWithReason`
        await this.$http.put(apiPath, {
          carrierId: this.version.carrierId,
          trackingId: this.version.trackingId,
          status: this.parcelStatus,
          reason: this.reasonChange,
          additionalInfo: this.additionalInfo
        })
        this.showUpdateStatus = false
        this.$emit('notify')
      } catch (error) {
        alert('An error occurred while updating status, please try again')
      }
    },
    async regeneratePinClick (version) {
      try {
        const user = await Auth.currentAuthenticatedUser()
        this.$http.defaults.headers.common.Authorization = user.signInUserSession.idToken.jwtToken
        const apiPath = `${process.env.VUE_APP_API_BASE}/pin/parcel/${this.$route.params.carrierId}/${this.$route.params.trackingId}`
        const result = await this.$http.get(apiPath)
        this.showUpdateStatus = false
        if (result.status === 200) {
          alert('PIN has been regenerated successfully')
        } else {
          alert('Failed to regenerated the PIN')
        }
      } catch (error) {
        alert('An error occurred while calling the regenerate pin funcion, please try again')
      }
    },
    /**
     * Get Google Maps link
     */
    getGoogleMapsLink () {
      return `https://maps.google.com/?q=${this.version.location.latitude},${this.version.location.longitude}`
    },
    /**
     * Check date is empty
     */
    isNotEmptyDate (value) {
      return value !== '0001-01-01T00:00:00Z'
    },
    /**
     * Get formatted lookup reason
     */
    getFormattedLookupReason (reason) {
      return (this.lookupReasons[reason]) ? this.lookupReasons[reason] : reason
    },
    async getAvailableActions () {
      const user = await Auth.currentAuthenticatedUser()
      this.$http.defaults.headers.common.Authorization = user.signInUserSession.idToken.jwtToken
      let apiPath = `${process.env.VUE_APP_API_BASE}/actions/getAvailableActions/`
      let labelId = this.version.labelId
      if (labelId.length > 27) {
        if (labelId.charAt(0) === '%') {
          labelId = labelId.slice(1)
        }
      }
      if (labelId.search('/') > -1) {
        labelId = labelId.replaceAll('/', '<p>')
      }
      apiPath += `${this.version.carrierId}/${this.version.branchId}/${labelId}`
      try {
        const newActions = []
        const carrierAvailableActions = await this.$http.get(apiPath)
        carrierAvailableActions.data.result.AvailableActions.map(action => {
          const mappedAction = this.mapAvailableAction(action)
          if (mappedAction !== false) {
            newActions.push(mappedAction)
          }
        })
        newActions.push('EXPIRED_COLLECTION')
        newActions.push('PARCEL_NOT_IN_BRANCH')
        this.parcelStatuses = newActions
      } catch (e) {
        this.parcelStatuses = [
          'EXPIRED_COLLECTION',
          'PARCEL_NOT_IN_BRANCH'
        ]
        this.availableActionsError = true
      }
    },
    mapAvailableAction (carrierAction) {
      const carrierToInternalMap = [
        {
          internal: 'BRANCH_CHECKED_IN',
          carrier: 'Consignment-Received-For-Delivery'
        },
        {
          internal: 'CUSTOMER_COLLECTED',
          carrier: 'Confirm-Validation-Code'
        },
        {
          internal: 'CUSTOMER_REFUSED',
          carrier: 'Parcel-Return'
        },
        {
          internal: 'CARRIER_COLLECTED',
          carrier: 'Despatch-With-Courier'
        },
        {
          internal: 'CUSTOMER_DROPPED_OFF',
          carrier: 'Cust-Dropoff-Paid'
        }
      ]
      const mappedAction = carrierToInternalMap.filter(action => action.carrier === carrierAction)
      if (mappedAction.length !== 1) {
        return false
      }
      return mappedAction[0].internal
    }
  }
}
</script>

<style scoped>
  .parcel-row {
    @apply py-4 sm:grid sm:py-3 sm:grid-cols-3 sm:gap-4;
  }
  .edit {
    text-decoration: underline;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
    cursor: pointer;
    margin-left: 5px;
  }
</style>
